<template>
  <div class="w-full px-4 lg:px-0 lg:w-[1000px] absolute top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] font-serif">
    <h2 class="pb-10 text-3xl font-bold">{{ subTitle }}</h2>
    <h1 class="text-8xl font-bold text-[#60A3D9] pb-2">{{ title }}</h1>
    <p class="pb-10 text-lg">{{ subText }}</p>
    <div class="flex flex-wrap">
      <a class="mr-8 flex transition-colors items-center px-4 py-2 border-2 border-black rounded-lg hover:text-[#60A3D9] hover:border-[#60A3D9] cursor-pointer" href="mailto:wpark022@gmail.com">
        <font-awesome-icon :icon="['fas', 'paper-plane']" class="w-4 h-4 pr-3"/>
        <span class="">
          Get in Touch
        </span>
      </a>
      <a class="transition-colors cursor-pointer flex items-center px-4 py-2 bg-black border-2 border-black rounded-lg hover:bg-[#60A3D9] hover:border-[#60A3D9]" href="https://drive.google.com/file/d/1rh8gc_3fnS0WI5gOcFyoJ2GSQO7PSchG/view?usp=sharing">
        <font-awesome-icon :icon="['fas', 'file-pdf']" class="w-4 h-4 pr-3 text-white" />
        <span class="text-white">
          Resume
        </span>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    subTitle: String,
    title: String,
    subText: String,
  },
  setup() {
    return {

    }
  },
}
</script>