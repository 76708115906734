<template>
  <div class="fixed top-0 z-10 flex items-center w-full px-4 py-2 bg-white desktop" ref="headRef">
    <a @click="scrollIn('main-section')" class="cursor-pointer">
      <img src="../../public/images/Favicon.jpg" class="w-16 h-16 rounded-full"/>
    </a>
    <ul class="flex ml-auto mr-10 space-x-10 font-serif text-lg font-medium">
      <li @click="scrollIn('about-section')" class="hover:text-[#60A3D9] cursor-pointer transition-colors">About</li>
      <li @click="scrollIn('project-section')" class="hover:text-[#60A3D9] cursor-pointer transition-colors">Projects</li>
      <li @click="scrollIn('experience-section')" class="hover:text-[#60A3D9] cursor-pointer transition-colors">Experience</li>
    </ul>
  </div>
  <div class="fixed top-0 z-10 w-full px-4 py-2 bg-white mobile" ref="headRef1">
    <div class="flex items-center w-full">
      <a @click="scrollIn('main-section')" class="cursor-pointer">
        <img src="../../public/images/Favicon.jpg" class="w-16 h-16 rounded-full"/>
      </a>
      <font-awesome-icon @click="openMenu" :icon="['fa', 'bars']" class="ml-auto w-7 h-7"/>
      <!-- <div class="ml-auto">
        <input type="checkbox" id="checkbox3" class="checkbox3 visuallyHidden" @click="openMenu">
        <label for="checkbox3">
          <div class="hamburger hamburger3">
            <span class="bar bar1"></span>
            <span class="bar bar2"></span>
            <span class="bar bar3"></span>
            <span class="bar bar4"></span>
          </div>
        </label>
      </div> -->
    </div>
    <ul id="myLinks" class="pt-3 pl-3 font-serif text-lg font-medium">
      <li @click="scrollIn('about-section')" class="hover:text-[#60A3D9] cursor-pointer pt-2">About</li>
      <li @click="scrollIn('project-section')" class="hover:text-[#60A3D9] cursor-pointer pt-2">Projects</li>
      <li @click="scrollIn('experience-section')" class="hover:text-[#60A3D9] cursor-pointer pt-2">Experience</li>
    </ul>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue";

  export default {
    setup() {
      const headRef = ref(null);
      const headRef1 = ref(null);

      onMounted(() => {
        window.addEventListener("scroll", () => {
          var curr = window.pageYOffset;
          if (curr !== 0) {
            headRef.value.classList.add("scroll");
          }
          if (curr === 0) {
            headRef.value.classList.remove("scroll");
          }
        });
        window.addEventListener("scroll", () => {
          var curr = window.pageYOffset;
          if (curr !== 0) {
            headRef1.value.classList.add("scroll");
          }
          if (curr === 0) {
            headRef1.value.classList.remove("scroll");
          }
        });
      });

      let scrollIn = (id) => {
        if(id == 'main-section') {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else {
          const yOffset = -110; 
          const element = document.getElementById(id);
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({top: y, behavior: 'smooth'});
        }
        var x = document.getElementById("myLinks");
        x.style.display = "none";
      };

      let openMenu = () => {
        var x = document.getElementById("myLinks");

        if (x.style.display === "block") {
          x.style.display = "none";
        } 
        
        else {
          x.style.display = "block";
        }
      }
      
      return { 
        headRef,
        scrollIn,
        openMenu,
        headRef1,
      };
    },
  }
</script>

<style scoped>
  .scroll {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.5s;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: 660px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    #myLinks {
      display: none;
    }

    .visuallyHidden {
      position: absolute; 
      overflow: hidden; 
      clip: rect(0 0 0 0); 
      height: 1px; width: 1px; 
      margin: -1px; padding: 0; border: 0; 
    }

    .hamburger {
      margin: 0 auto;
      width: 30px;
      height: 30px;
      position: relative;
    }

    .hamburger .bar {
      padding: 0;
      width: 30px;
      height: 4px;
      background-color: black;
      display: block;
      border-radius: 4px;
      transition: all 0.4s ease-in-out;
      position: absolute; 
    }

    .bar1 {
      top: 0;
    }

    .bar2,
    .bar3 {
      top: 13.5px;
    }

    .bar3 {
      right: 0;
    }

    .bar4 {
      bottom: 0;
    }
    .hamburger3 .bar1 {
      transform-origin: 5%;
    }

    .hamburger3 .bar4 {
      transform-origin: 5%;
    }

    .checkbox3:checked + label > .hamburger3 > .bar1{
      transform: rotate(45deg);
      height: 3px;
      width: 42px;
    }

    .checkbox3:checked + label > .hamburger3 > .bar3{
      transform: rotate(45deg);
      height: 3px;
      background-color: transparent;
    }

    .checkbox3:checked + label > .hamburger3 > .bar2{
      transform: rotate(-45deg);
      height: 3px;
      background-color: transparent;
    }

    .checkbox3:checked + label > .hamburger3 > .bar4{
      transform: rotate(-45deg);
      height: 3px;
      width: 42px;
    }
  }
</style>