<template>
  <div class="w-full xl:w-11/12 xl:mx-auto bg-[#e8f4f8] px-4 py-16 md:px-28 md:py-5 rounded-xl font-serif">
    <div class="flex flex-wrap items-center md:flex-nowrap">
      <div class="w-full mr-5 md:w-1/2">
        <div class="flex items-center mb-8">
          <div class="rounded-full w-16 h-16 bg-[#add8e6] flex items-center mr-5">
            <font-awesome-icon :icon="['fa', 'user']" class="w-8 h-8 m-auto"/>
          </div>
          <h3 class="text-4xl font-semibold">About Me</h3>
        </div>
        <p class="text-base">
          I graduated with a B.S. in Computer Science at the University of California, Riverside.
          I try to make websites go beep, but they would always boop back.
          My hobbies include mixing/djing and finding good places to eat on yelp.
        </p>
      </div>
      <div class="w-full md:w-1/2">
        <img src="../../public/images/IMG_3893.png" class="ml-auto mr-auto"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
      
  },
}
</script>