import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane, faFilePdf, faUser, faListCheck, faHotel, faFilm, faCloudSun, faBurger, faCalculator, faBriefcase, faCircleCheck, faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faGithub , faLinkedin, faSoundcloud} from '@fortawesome/free-brands-svg-icons'
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAsjFv5LZeahjKqtR15tRAUZ664bkkEBDc",
  authDomain: "willparkwebsite.firebaseapp.com",
  projectId: "willparkwebsite",
  storageBucket: "willparkwebsite.appspot.com",
  messagingSenderId: "319113901890",
  appId: "1:319113901890:web:835f6921f59a8aee83badb",
  measurementId: "G-GV9H2H4JQN"
};

library.add(
  faPaperPlane, 
  faFilePdf, 
  faUser, 
  faListCheck, 
  faHotel, 
  faFilm, 
  faCloudSun, 
  faBurger, 
  faCalculator, 
  faTwitter, 
  faBriefcase, 
  faCircleCheck,
  faGithub,
  faBars,
  faLinkedin,
  faSoundcloud,
)
firebase.initializeApp(firebaseConfig);
createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')