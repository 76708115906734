<template id="experience">
<div>
  <div class="w-full lg:w-[1000px] font-serif lg:mx-auto px-4">
    <div class="flex items-center mb-8">
      <div class="rounded-full w-16 h-16 bg-[#f2ead4] flex items-center mr-5">
        <font-awesome-icon :icon="['fa', 'briefcase']" class="w-8 h-8 m-auto"/>
      </div>
      <h3 class="text-4xl font-semibold">Experience</h3>
    </div>
  </div>
  <div class="w-full xl:w-11/12 xl:mx-auto bg-[#F4F8E8] px-4 py-16 md:px-4 md:py-10 rounded-xl font-serif mb-10">
    <div class="flex flex-wrap items-center md:flex-nowrap">
      <div class="w-full pb-16 md:w-1/2">
        <img :src="dutchLogo" alt="Dutch Logo" class="h-20 ml-auto mr-auto" />
      </div>
      <div class="w-full mr-5 md:w-1/2">
        <div class="flex items-center">
          <h3 class="text-lg font-semibold">Frontend Engineer <a href="https://www.dutch.com/">@ Dutch Pet</a></h3>
        </div>
        <h4 class="text-base">November 2021 - May 2022</h4>
        <div class="border-b-2 rounded-full border-[#d8e6ad] my-2"></div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Extended and scaled new interfaces and features for customer account and registration flow using Vue, Tailwind, and Shopify CLI</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Strengthened customer satisfaction with vet and product surveys, which connected data with the EMR backend</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Improved site irregularities and bugs through evaluations in BugSnag</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Refactored Klaviyo flows to perform correct triggers and layouts for email templates</p>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full xl:w-11/12 xl:mx-auto bg-[#F8ECE8] px-4 py-16 md:px-4 md:py-10 rounded-xl font-serif mb-10">
    <div class="flex flex-wrap items-center md:flex-nowrap">
      <div class="w-full pb-16 md:w-1/2">
        <img src="../../public/images/Pedal_Commander_logo.webp" alt="Pedal Commander Logo" class="h-20 ml-auto mr-auto"/>
      </div>
      <div class="w-full mr-5 md:w-1/2">
        <div class="flex items-center">
          <h3 class="text-lg font-semibold">Frontend Developer @ Pedal Commander</h3>
        </div>
        <h4 class="text-base">January 2020 - November 2021</h4>
        <div class="border-b-2 rounded-full border-[#e6bcad] my-2"></div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Developed single page web applications using global eCommerce platform (Shopify Plus) using Liquid, HTML, CSS, and JavaScript</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Created an interactive product map using JavaScript that increased customer service productivity by around 10%</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Designed high and low fidelity mockups using Adobe XD to outline and plan sale campaigns</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Increased overall store conversion rate from less than 1% to 2.4% and decrease overall store bounce rate by around 20%</p>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full xl:w-11/12 xl:mx-auto bg-[#E8ECF8] px-4 py-16 md:px-4 md:py-10 rounded-xl font-serif">
    <div class="flex flex-wrap items-center md:flex-nowrap">
      <div class="w-full pb-16 md:w-1/2">
        <img src="../../public/images/tego-logo.png" alt="Tego Auto Logo" class="h-20 ml-auto mr-auto"/>
      </div>
      <div class="w-full mr-5 md:w-1/2">
        <div class="flex items-center">
          <h3 class="text-lg font-semibold">Freelance - Frontend @ Tego Auto Products</h3>
        </div>
        <h4 class="text-base">May 2021 - July 2021</h4>
        <div class="border-b-2 rounded-full border-[#adbce6] my-2"></div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Implemented a store for client’s personal startup business using eCommerce platform Shopify</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Designed wireframes to create landing pages using Liquid, HTML, CSS, and Javascript</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Collaborated with SiteTuners to discuss best frontend practices and layouts for Shopify</p>
        </div>
        <div class="flex items-baseline text-[1rem]">
          <font-awesome-icon :icon="['fa', 'check-circle']" class="w-3 h-3 mr-2"/>
          <p>Evaluated consumer behavior for highest conversion rates through A/B Testing using Google Optimize</p>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import dutchLogo from "@/assets/DutchLogo.svg";

export default {
  setup() {
    return {
      dutchLogo,
    }
  },
}
</script>