<template>
  <HeaderMenu />
  <div class="w-full xl:w-[1200px] mr-auto ml-auto">
    <div class="h-screen">
      <MainSection 
        subTitle="Hi 👋, my name is" 
        title="Will Park" 
        subText="I'm a frontend engineer that likes to build stuff for the web."
        id="main-section"
      />
    </div>
    <div class="space-y-40">
      <AboutSection id="about-section"/>
      <ProjectSection id="project-section"/>
      <ExperienceSection id="experience-section"/>
    </div>
    <div class="pt-20 pb-20">
      <div class="flex items-center justify-center ml-auto mr-auto">
        <a href="https://github.com/willypark22">
          <font-awesome-icon :icon="['fa-brands', 'github']" class="text-black w-7 h-7 hover:text-[#60A3D9] transition-colors"/>
        </a>
        <a href="https://www.linkedin.com/in/willypark22/" class="ml-12 mr-12">
          <font-awesome-icon :icon="['fa-brands', 'linkedin']" class="text-black w-7 h-7 hover:text-[#60A3D9] transition-colors"/>
        </a>
        <a href="https://soundcloud.com/willypark22">
          <font-awesome-icon :icon="['fa-brands', 'soundcloud']" class="text-black w-7 h-7 hover:text-[#60A3D9] transition-colors"/>
        </a>
      </div>
      <p class="flex justify-center mt-8 ml-auto mr-auto font-serif">Made with 🎵 by Will Park.</p>
    </div>
  </div>
</template>

<script>
import HeaderMenu from "@/components/HeaderMenu";
import MainSection from "@/components/MainSection";
import AboutSection from "@/components/AboutSection";
import ProjectSection from "@/components/ProjectSection";
import ExperienceSection from "@/components/ExperienceSection";

export default {
  name: 'App',
  components: {
    HeaderMenu,
    MainSection,
    AboutSection,
    ProjectSection,
    ExperienceSection,
}
}
</script>