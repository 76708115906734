<template>
  <div>
    <div class="w-full lg:w-[1000px] px-4 font-serif lg:mx-auto">
      <div class="flex items-center mb-8">
        <div class="rounded-full w-16 h-16 bg-[#f2ead4] flex items-center mr-5">
          <font-awesome-icon :icon="['fa', 'list-check']" class="w-8 h-8 m-auto"/>
        </div>
        <h3 class="text-4xl font-semibold">Projects</h3>
      </div>
    </div>
    <div class="w-full px-2 mx-auto font-serif">
      <div class="flex flex-wrap justify-center mx-2 lg:mx-10">
        <div class="w-full basis-auto shrink-0 grow-0">
          <div class="flex flex-wrap justify-center">
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">Beach Resort App</h3>
                <p>A web application that mocks a beach resort website.</p>
                <a href="https://github.com/willypark22/hotel-reservation-react">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">Twitter Search Engine</h3>
                <p>Searches through tweets based on inputted keywords and ranks results based on credibility.</p>
                <a href="https://github.com/willypark22/Twitter-Crawler-Search-Engine">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">The Shoppies</h3>
                <p>Nominate your favorite movies and share them through social media.</p>
                <a href="https://github.com/willypark22/the-shoppies-react">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">Weather App</h3>
                <p>Search your favorite cities to check the weather.</p>
                <a href="https://github.com/willypark22/weather-react">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">Recipe App</h3>
                <p>Look up recipes for your favorite dishes.</p>
                <a href="https://github.com/willypark22/react-recipe-app">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
            <div class="w-full px-3 sm:w-1/2 lg:w-1/3 basis-auto shrink-0 grow-0">
              <div class="border-black border-[1px] p-5 rounded-md h-[230px] relative mb-10 card">
                <h3 class="mb-2 text-base font-semibold">iOS Calculator App</h3>
                <p>A web application designed to copy the IOS calculator application.</p>
                <a href="https://github.com/willypark22/ios-calculator-react">
                  <font-awesome-icon :icon="['fa-brands', 'github']" class="hover:text-[#60A3D9] transition-colors absolute w-6 h-6 m-auto bottom-5"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
}
</script>

<style scoped>
  .card {
    box-shadow: 10px 10px #60A3D9;
    transition: 0.2s;
  }
  .card:hover {
    box-shadow: none;
  }
</style>